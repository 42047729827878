import React, {useState, useRef} from 'react';
import {getImportReports, deleteImportReport} from '../../services/ApiService';
import {DateCell, ActionCell} from 'jnpsoft-react-utilities/dist/JnpTable/Commons';
import TemplateManage from "../TemplateManage";
import { useHistory } from "react-router-dom";
import {ImportConfigurationRoute} from "../routes";
import DescriptionIcon from '@mui/icons-material/Description';
import ImportReportDialog from '../dialogs/ImportReportDialog';

const ImportTypes = {
    1: 'ACES XML',
    2: 'ACES EXCEL',
    3: "CONVERT EXCEL TO XML",
    4: "CONVERT XML TO EXCEL",
}

const ImportStatusSuccess = "SUCCESS";
const ImportStatusFailed = "FAILED";
const ImportStatusEmpty = "EMPTY";
const ImportStatusProcessing = "PROCESSING";

const createColumnDefinitions = (action) => [
    {
        Header: 'Import Name',
        accessor: 'importName',
        width: 200,
    },
    {
        Header: 'User Name',
        accessor: 'username'
    },
    {
        Header: 'Import Type',
        accessor: 'importType',
        Cell: ({value}) => ImportTypes[value],
        width: 200,
    },
    {
        Header: 'Status',
        width: 200,
        accessor: 'importResultReportBase.errorsCount',
        Cell: 
            ({value, row}) => 
                !!!row?.original?.importResultReportBase
                ? ImportStatusProcessing
                : value > 0 
                    ? ImportStatusFailed 
                    : row?.original?.importResultReportBase?.importedCount > 0 
                        ? ImportStatusSuccess 
                        : ImportStatusEmpty,
    },
    {
        Header: 'Date',
        accessor: 'date',
        width: 200,
        Cell: DateCell,
    },
    {
        Header: 'Report',
        width: 290,
        accessor: 'importResultReportBase',
        Cell: props => !!props?.row?.original?.importResultReportBase ? ActionCell(props, () => <DescriptionIcon /> ,action) : '',
    }
];

const Export = ({name, icon}) => {

    let history = useHistory();
    const [reportContents, setReportContents] = useState([]);
    const [isError, setError] = useState(false);
    const templateMangeRef = useRef(null);
    
    const handleExport = () => history.push(ImportConfigurationRoute);
    const handleRefresh = () => {
        if (templateMangeRef) {
            templateMangeRef.current.refreshData();
        }
    };

    const openImportReport = (report) => {
        if (Boolean(report))
        {
            setError(report.errorsCount > 0);
            setReportContents(report);
        }
        else {
            setError(false);
            setReportContents(report);
        }
    }

    const columnDefinitions = createColumnDefinitions(openImportReport);

    const actions = [
        {
            label: "New Import",
            name: "newImport",
            onAction: handleExport,
        },
        {
            label: "Refresh",
            name: "refresh",
            onAction: handleRefresh,
        },
    ];

    return (
        <React.Fragment>
            <TemplateManage
                keyPropertyId="importReportId"
                label={name}
                title={name}
                ref={templateMangeRef}
                titleIcon={icon()}
                columnDefs={columnDefinitions}
                getAllObjects={getImportReports}
                deleteObject={deleteImportReport}
                defaultSortColumn={{id:"date", desc: true}}
                additionalActions={actions} />
            <ImportReportDialog
                title={"Import status"}
                contents={reportContents}
                setContents={setReportContents}      
                isError={isError}
            />
        </React.Fragment>
    );
}

export default Export;