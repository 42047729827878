import { read } from 'xlsx';

export const resolveSheetNamesFromFile = (file, onData = () => {}) => {
  let reader = new FileReader();

  reader.onload = function(e) {
      let arrayBuffer = new Uint8Array(reader.result);
      const wb = read(arrayBuffer);
      onData(wb.SheetNames.map((x, i) => ({id: i, value: x})));
  }

  reader.readAsArrayBuffer(file);
}