import React, { useEffect, useState } from "react";
import { getCustomAcesAttribute, postCustomAcesAttribute, deleteCustomAcesAttribute, getCustomAcesAttributeFieldNames } from '../../services/ApiService';
import TemplateManage from "../TemplateManage";
import {DateCell} from 'jnpsoft-react-utilities/dist/JnpTable/Commons';
import Grid from '@mui/material/Grid';
import GeneralTextField from "../GeneralTextField";
import GeneralVirtualizedAutocomplete from "../GeneralVirtualizedAutocomplete";

const customAcesAttributeColumnDefs = [
    {
        Header: 'Id',
        accessor: 'acesArticleLinkageCustomAttributeId',
        maxWidth: 60,
    },
    {
        Header: 'Attribute Field Name',
        accessor: 'attributeFieldName'
    },
    {
      Header: 'Attribute Value',
      accessor: 'attributeValue'
    },
    {
        Header: 'Creation Date',
        Cell: DateCell,
        accessor: 'creationDate'
    },
    {
      Header: 'Modification Date',
      Cell: DateCell,
      accessor: 'modificationDate'
    } 
];

const createCustomAcesAttribute = () => ({
  acesArticleLinkageCustomAttributeId: -1,
  attributeFieldName: '',
  attributeValue: '',
});

const ManageCustomAcesAttribute = ({name, icon}) => {

    const setCustomAcesAttribute = (customAcesAttribute, setCustomAcesAttribute) => setCustomAcesAttribute({...customAcesAttribute});
    const [attributeFieldNames, setAttributeFieldNames] = useState([]);
    const validations = [
        (customAcesAttribute) => {
            const error = customAcesAttribute.attributeFieldName && customAcesAttribute.attributeFieldName.length ? undefined : "Attribute field name is required";
            return {attributeFieldName: error};
        },
        (customAcesAttribute) => {
          const error = customAcesAttribute.attributeValue && customAcesAttribute.attributeValue.length ? undefined : "Attribute value is required";
          return {attributeValue: error};
        }
    ];

    useEffect(() => {
      getCustomAcesAttributeFieldNames(d => setAttributeFieldNames(d.map(x => ({'value': x}))));
    }, [])

    return (
        <TemplateManage
            keyPropertyId="acesArticleLinkageCustomAttributeId"
            label="Custom Attributes"
            title={name}
            titleIcon={icon()}
            columnDefs={customAcesAttributeColumnDefs}
            createDefaultObject={createCustomAcesAttribute}
            getAllObjects={getCustomAcesAttribute}
            postObject={postCustomAcesAttribute}
            deleteObject={deleteCustomAcesAttribute}
            validations={validations}
            uniqueId="manage_custom_aces_attribute"
            defaultSortColumn="attributeFieldName"
            renderInputs={({editValue, setEditValue, errors}) => {
                const selectedAttributeFieldName = attributeFieldNames.find(x => x.value === editValue?.attributeFieldName);
                return (<Grid container spacing={2}>
                    <Grid item xs={12}>
                          <GeneralVirtualizedAutocomplete
                              options={attributeFieldNames}
                              value={selectedAttributeFieldName}
                              label="Attribute Field Name"
                              keyId="value"
                              keyName="value"
                              disabled={!!editValue?.isUsed}
                              setId={v => setCustomAcesAttribute({...editValue, attributeFieldName: v}, setEditValue)}
                              handleError={ (p) => !!errors[p] }
                              getHelperText={ (p) => errors[p] || '' }
                          />
                    </Grid>
                    <Grid item xs={12}>
                      <GeneralTextField 
                            keyPropertyName="attributeValue"
                            label="Attribute Value"
                            itemValue={editValue}
                            setItemValue={v => setCustomAcesAttribute(v, setEditValue)}
                            handleError={ (p) => !!errors[p] }
                            getHelperText={ (p) => errors[p] || '' }
                        />
                    </Grid>
                </Grid>);
            }
            }
        />
    );
};

export default ManageCustomAcesAttribute;