import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { JnpDivTable, DivTableStyles } from "jnpsoft-react-utilities/dist/JnpTable";
import _ from 'lodash';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { errorMessageFactory, errorMessageFactoryCSV, errorTypes } from '../../services/AcesErrorService';

const columnDefs = [{        
    Header: 'Line',
    accessor: 'line',
}, {
    Header: 'Error Type',
    accessor: 'errorType',
}, {
    Header: 'Error Message',
    accessor: 'errorMessage',
    csvGetter: (row,value) => {
      return row.errorMessageCSV
    },
    width: 800,
}];

const columnCustomAndDuplicateDefs = [{        
  Header: 'Line',
  accessor: 'line',
}, {
  Header: 'Message',
  accessor: 'errorMessage',
  width: 800,
}];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
      height: '80vh',
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  const ImportTabPanel = (props) => {
    const { tableRef, value, columnDefs, title, rows = [] } = props;
    return (
      <TabPanel 
        value={value}
        sx={{
          flexGrow: 1,
          padding: '24px 0',
          maxHeight: 'calc(100% - 124px)',
        }}>
        {
          rows?.length === 300
          ? <Box paddingBottom={2}>
             <Typography sx={{color: 'red'}}>🚨 More than 300 errors were found. Only 300 will show.</Typography>
            </Box>
          : null
        }
        <DivTableStyles>
            <JnpDivTable
              columns={columnDefs}
              ref={tableRef}
              title={title}
              data={rows}
              allowGroupBy={true}
              setSelectedRows={() => {}}
              selectedBackgroundColor='#BEBEBE'
            />
        </DivTableStyles>
    </TabPanel>
    );
  }
  
const ImportReportDialog = ({title, contents = {}, setContents, isError = false}) => {

    const [importReport, setImportReport] = useState(null);
    const [errorRows, setErrorRows] = useState([]);
    const [customRows, setCustomRows] = useState([]);
    const [duplicateRows, setDuplicateRows] = useState([]);
    const [tabValue, setTabValue] = useState("errors");
    const [tabDisabledState, setTabDisabledState] = useState({});
    const [displayTables, setDisplayTables] = useState(false);
    const errorsRef = useRef(null);
    const customsRef = useRef(null);
    const duplicatesRef = useRef(null);

    useEffect(() => {
      if (!_.isEqual(contents, importReport)) {
        setImportReport(contents);
      }
    },[contents]);

    useEffect(() => {
      const errorRows = createRows(importReport?.errors);
      const customRows = _.uniqBy(createRows(importReport?.customs), v => v?.line);
      const duplicateRows = createRows(importReport?.duplicates);
      setErrorRows(errorRows);
      setCustomRows(customRows);
      setDuplicateRows(duplicateRows);
      setTabDisabledState({
        "errors": !!!errorRows?.length,
        "customs": !!!customRows?.length,
        "duplicates": !!!duplicateRows?.length,
      });
      const hasData = !!errorRows?.length || !!customRows?.length || !!duplicateRows?.length;
      setDisplayTables(hasData);

      if (hasData) {
        const newTabValue = !!errorRows?.length ? 'errors' : !!customRows?.length ? 'customs' : 'duplicates';
        setTabValue(newTabValue);
      }

    }, [importReport]);

    useEffect(() => {
      errorsRef?.current?.expandAllRows();
      customsRef?.current?.expandAllRows();
      duplicatesRef?.current?.expandAllRows();
    },[errorsRef, customsRef, duplicatesRef, errorRows, customRows, duplicateRows, tabValue]);

    const createRows = source => source?.map(x => ({
        ...x,
        errorType: errorTypes[x.errorType] ?? 'Unknown',
        errorMessage: errorMessageFactory(x),
        errorMessageCSV: errorMessageFactoryCSV(x)
    })) ?? [];

    const handleClose = () => {
        setContents({});
    };

    const handleTabValue = (evt, v) => {
      setTabValue(v);
    }

    return (
        <BootstrapDialog aria-labelledby="customized-dialog-title" open={Object.keys(contents).length>0} maxWidth={'xl'} fullWidth={true}>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                {title}
            </BootstrapDialogTitle>
            <DialogContent dividers style={{whiteSpace: 'pre-wrap'}}>
                <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                  <Box display='flex' justifyContent='space-between' padding='0px 24px'>
                      <div>
                        <Typography>{`✅ Imported Lines: ${contents.importedCount}`}</Typography>
                        <Typography>{`⛔ Invalid Lines: ${contents.errorsCount}`}</Typography>
                      </div>
                      <div>
                        <Typography>{`✅ Article Numbers: ${contents.articleNumberCount}`}</Typography>
                        <Typography>{`⛔ VCDB Issues: ${contents.vcdbErrorCount}`}</Typography>
                      </div>
                      <div>
                        <Typography>{`✅ Fitment Notes: ${contents.fitmentNoteCount}`}</Typography>
                        <Typography>{`⛔ PCDB Issues: ${contents.pcdbErrorCount}`}</Typography>
                      </div>
                      <div>
                        <Typography>{`✅ Qualifiers: ${contents.qualifierCount}`}</Typography>
                        <Typography>{`⛔ QDB Issues: ${contents.qdbErrorCount}`}</Typography>
                      </div>
                      <div>
                        <Typography>{`✅ Customs Lines: ${contents.customCount}`}</Typography>
                        <Typography>{`⛔ Duplicated Lines: ${contents.duplicateCount}`}</Typography>
                      </div>
                  </Box>
                  { displayTables ?
                  <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleTabValue}>
                        <Tab label="Errors" value="errors" disabled={tabDisabledState['errors'] ?? false}/>
                        <Tab label="Customs" value="customs" disabled={tabDisabledState['customs'] ?? false}/>
                        <Tab label="Duplicates" value="duplicates" disabled={tabDisabledState['duplicates'] ?? false}/>
                      </TabList>
                    </Box>
                      <ImportTabPanel 
                        value="errors"
                        title="Errors"
                        columnDefs={columnDefs}
                        rows={errorRows}
                        tableRef={errorsRef}
                      />
                      <ImportTabPanel 
                        value="customs"
                        title="Number of valid custom lines"
                        columnDefs={columnCustomAndDuplicateDefs}
                        rows={customRows}
                        tableRef={customsRef}
                      />
                      <ImportTabPanel 
                        value="duplicates"
                        title="Duplicates"
                        columnDefs={columnCustomAndDuplicateDefs}
                        rows={duplicateRows}
                        tableRef={duplicatesRef}
                      />
                    </TabContext>
                    : !!importReport?.errorMessages?.length
                      ? <Typography>{`⛔ ${importReport.errorMessages[0]}`}</Typography>
                      : <Typography>{`✅ Data 100% valid`}</Typography>
                    }
                </div>
            </DialogContent>
        </BootstrapDialog>
    );
}

export default ImportReportDialog;