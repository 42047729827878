import React from "react";
import { getAllLifeCycles, postLifeCycle, deleteLifeCycle } from '../../services/ApiService';
import TemplateManage from "../TemplateManage";
import {DateCell} from 'jnpsoft-react-utilities/dist/JnpTable/Commons';
import Grid from '@mui/material/Grid';
import GeneralTextField from "../GeneralTextField";

const lifeCycleColumnDefs = [
    {
        Header: 'Id',
        accessor: 'lifeCycleId',
        maxWidth: 60,
    },
    {
        Header: 'Code',
        accessor: 'code',
        maxWidth: 80,
    },
    {
        Header: 'Life Cycle',
        accessor: 'lifeCycleName'
    },
    {
        Header: 'Date',
        Cell: DateCell,
        accessor: 'date'
    }    
];

const createLifeCycle = (lifeCycleId = -1, lifeCycleName = '', code = '') => ({
    lifeCycleId,
    lifeCycleName,
    code
});

const ManageLifeCycle = ({name, icon}) => {

    const setLifeCycle = (lifeCycle, setLifeCycle) => setLifeCycle({...lifeCycle});

    const validations = [
        (lifeCycle) => {
            const error = lifeCycle.isDefault ? "Default" : undefined;
            return {key: error};
        },
        (lifeCycle) => {
            const error = lifeCycle.lifeCycleName && lifeCycle.lifeCycleName.length ? undefined : "Life Cycle name is required";
            return {lifeCycleName: error};
        },
        (lifeCycle) => {
            const error = lifeCycle.code && lifeCycle.code.length === 3 ? undefined : "Life Cycle code is required (3 characters)";
            return {code: error};
        }
    ];

    return (
        <TemplateManage
            keyPropertyId="lifeCycleId"
            label="Life Cycle"
            title={name}
            titleIcon={icon()}
            columnDefs={lifeCycleColumnDefs}
            createDefaultObject={createLifeCycle}
            getAllObjects={getAllLifeCycles}
            postObject={postLifeCycle}
            deleteObject={deleteLifeCycle}
            validations={validations}
            defaultSortColumn="code"
            renderInputs={({editValue, setEditValue, errors}) => 
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <GeneralTextField 
                            keyPropertyName="lifeCycleName"
                            label="Name"
                            itemValue={editValue}
                            setItemValue={v => setLifeCycle(v, setEditValue)}
                            handleError={ (p) => !!errors[p] }
                            getHelperText={ (p) => errors[p] || '' }
                            disabled={editValue.isDefault}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GeneralTextField 
                            keyPropertyName="code"
                            label="Code"
                            itemValue={editValue}
                            setItemValue={v => setLifeCycle(v, setEditValue)}
                            handleError={ (p) => !!errors[p] }
                            getHelperText={ (p) => errors[p] || '' }
                            disabled={editValue.isDefault}
                        />
                    </Grid>
                </Grid>
            }
        />
    );
}

export default ManageLifeCycle;