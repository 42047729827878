import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";

export const signalRConnection =  new HubConnectionBuilder()
  .withUrl(`${process.env.REACT_APP_EQUIPMENT_DE_API_URL}/equipmenthub`, {
    skipNegotiation: true,
    transport: HttpTransportType.WebSockets,
    accessTokenFactory: () => {
      return localStorage.getItem("TOKEN");
    },
  })
  //.withAutomaticReconnect()
  .build();