import { TextField } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

const GeneralDatePicker = (props) => {
   
    const { 
        keyPropertyName, 
        label, 
        itemValue, 
        setItemValue,
        handleError = () => false,
        getHelperText = () => '',
        ...otherProps 
    } = props;

    const handleChange = (value) => {
        const updatedObj = {...itemValue, [keyPropertyName]:new Date(value).getTime()};
        setItemValue(updatedObj);
    };

    const id = `${keyPropertyName}-id`;

    const getFormattedDate = () => {
        if(itemValue[keyPropertyName]) {
            var date = new Date(itemValue[keyPropertyName]);
            return `${date.getFullYear()}/${(date.getMonth()+1).toString().padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}`;
        }
        return "";
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                id={id}
                label={label}
                inputFormat="yyyy/MM/dd"
                value={getFormattedDate()}
                onChange={handleChange}
                renderInput={(params) => <TextField
                    {...params}
                    color="primary"
                    fullWidth
                    variant='standard'
                    error={handleError(keyPropertyName)}
                    helperText={getHelperText(keyPropertyName)}
                    InputLabelProps={{ shrink: true }}
                    {...otherProps}
                />}
            />
        </LocalizationProvider>
    );
};

export default GeneralDatePicker;