import { AcesMappingTypes } from "../../services/AcesMappingTypeService";
export const createExportConfiguration = (
    exportConfigurationId = -1,
    exportConfigurationName = '',
    type = '',
    autocareHeaderId = null,
    brandId = -1,
    productLineIds = [],
    mfrLabelIds = [],
    lifeCycleIds = [],
    articleIds = [],
    convertToEngineBase,
    xmlAcesMappingType = AcesMappingTypes[0]?.value) => ({
    exportConfigurationId,
    exportConfigurationName,
    type,
    autocareHeaderId,
    brandId,
    productLineIds,
    mfrLabelIds,
    lifeCycleIds,
    articleIds,
    xmlAcesMappingType,
    convertToEngineBase
});