import React, {useState, useEffect, useContext} from "react";
import TemplateManage from "../TemplateManage";
import GeneralVirtualizedAutocomplete from "../GeneralVirtualizedAutocomplete";
import GeneralAutocomplete from "../GeneralAutocomplete";
import {createImportConfiguration} from './ImportConfigurationUtilities';
import { SnackBarContext} from '../../context';
import {DateCell} from 'jnpsoft-react-utilities/dist/JnpTable/Commons';
import GeneralTextField from "../GeneralTextField";
import Grid from '@mui/material/Grid';
import Dialog from '@material-ui/core/Dialog';
import { DropzoneArea } from 'material-ui-dropzone';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@mui/material/Button';
import {
    getAllImportConfigs,
    postImportConfig,
    deleteImportConfig,
    getAllAutocareHeader,
    getAllBrands,
    postImportEXCEL,
    postImportXML,
    postConvertXmlToExcel,
    postConvertExcelToXml,
} from '../../services/ApiService';
import { 
    createFilter,
    createDescription,
    createKeyValueObject
} from '../utilities';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { resolveSheetNamesFromFile } from '../../services/ExcelService';
import Box from "@mui/material/Box";

const convertLabel = 'Convert';
const importLabel = 'Import';
const excelLabel = 'Excel';
const xmlLabel = 'Xml';
const xmlToExcelLabel = 'Xml To Excel';
const excelToXmlLabel = 'Excel To Xml';
const actionTypes = [ {id: 1, name: convertLabel}, {id: 2, name: importLabel} ];
const formatTypes = [ 
    {id: 1, name: excelLabel},
    {id: 2, name: xmlLabel},
    {id: 3, name: xmlToExcelLabel},
    {id: 4, name: excelToXmlLabel}];


const ImportConfigurationColumnDefs = (headers, brands) => ([
    {
        Header: 'Id',
        accessor: 'importConfigurationId',
        maxWidth: 60,
    },
    {
        Header: 'Name',
        accessor: 'importConfigurationName',
        width: 180,
    },
    {
        Header: 'Action',
        accessor: 'action',
        width: 180,
    },
    {
        Header: 'Type',
        accessor: 'type',
        width: 180,
    },
    {
        Header: 'Autocare Header',
        accessor: 'autocareHeaderId',
        Cell: createDescription(headers),
		filter: createFilter(headers),
        width: 180,
    },
    {
        Header: 'Brand',
        accessor: 'brandId',
        Cell: createDescription(brands),
		filter: createFilter(brands),
        width: 180,
    },
    {
        Header: 'Date',
        Cell: DateCell,
        accessor: 'date'
    }    
]);

const ManageImportConfiguration = ({name, icon}) => {

    const [headers, setHeaders] = useState([]);
    const [brands, setBrands] = useState([]);
    const [config, setConfig] = useState({});
    const [importFile, setImportFile] = useState(null);
    const [openDropzoneArea, setOpenDropzoneArea] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [excelSheetNames, setExcelSheetNames] = useState([]);
    const [excelSheetName, setExcelSheetName] = useState(null);
    const {setSnackBarInfo} = useContext(SnackBarContext);

    useEffect(() => {        
        getAllAutocareHeader(setHeaders);
        getAllBrands(setBrands);
    },[]);

    const validations = [
        (importConfiguration) => {
            const error = importConfiguration.importConfigurationName && importConfiguration.importConfigurationName.length ? undefined : "Import Configuration name is required";
            return {importConfigurationName: error};
        },
        (importConfiguration) => {
            const headerNameError = isAutocareHeaderRequired(importConfiguration) && !!!importConfiguration?.autocareHeaderId ? "Autocare header is required" : undefined;
            return {headerName: headerNameError}
        },
        (importConfiguration) => {
            const brandError = importConfiguration.brandId ? undefined : "Brand is required";
            return {brandId: brandError};
        },
    ];

    const isAutocareHeaderRequired = (importConfiguration) => (importConfiguration?.type === xmlLabel || importConfiguration?.type === excelToXmlLabel);
    const isExcelSheetNameRequired = () => config.type?.toLowerCase() === excelLabel?.toLowerCase() || config.type?.toLowerCase() === excelToXmlLabel?.toLowerCase();
    const canUpload = () => !isExcelSheetNameRequired() || !!excelSheetName?.length;

    const setName = (name, setName) => setName({...name});

    const handleSetSelectedAutocareHeader = (autocareHeader, importConfiguration, setImportConfiguration) => {
        setImportConfiguration({...importConfiguration, autocareHeaderId: autocareHeader?.autocareHeaderId});
    };
    
    const handleSetBrandId = (id, importConfiguration, setImportConfiguration) => {
        setImportConfiguration({...importConfiguration, brandId: id});
    };

    const handleSetAction = (id, importConfiguration, setImportConfiguration) => {
        setImportConfiguration({...importConfiguration, action: actionTypes.find(x => x.id === id)?.name});
    };

    const handleSetType = (id, importConfiguration, setImportConfiguration) => {
        setImportConfiguration({...importConfiguration, type: formatTypes.find(x => x.id === id)?.name});
    };

    const resetImportFile = () => {
        setImportFile(null);
        setExcelSheetNames([]);
        setExcelSheetName(null);
    }

    const handleSetImportFileChange = (file) => {
        if(file.length > 0)
        {
            setImportFile(file[0]);
            resolveSheetNamesFromFile(file[0], d => {
                setExcelSheetNames(d)

                if (!!d?.length) {
                    setExcelSheetName(d[0]?.value);
                }
            });
        }
        else
        {
            resetImportFile();
        }
    };

    const handleStart = () => {
        const onError = (error) => {
            const msg = config.action === importLabel? "Unable to upload File" : "Unable to convert File";
            setSnackBarInfo({open: true, message: msg, severity: "error"});
            setIsLoading(false);
        };

        const onData = (response) => {
            
            if (response?.data) {
                if (config.action === importLabel)
                {
                    setSnackBarInfo({open: true, message: "Import started", severity: "info"});
                }
                else if (config.action === convertLabel)
                {
                    setSnackBarInfo({open: true, message: "Conversion started", severity: "info"});
                }
            }
            else {
                onError();
            }
            setIsLoading(false);
        }
        
        if(importFile)
        {
            setIsLoading(true);
            setOpenDropzoneArea(false);
            if (config.action === importLabel)
            {
                if (config.type?.toLowerCase() === excelLabel?.toLowerCase())
                {
                    postImportEXCEL(config.importConfigurationId, excelSheetName, importFile, onData, onError);
                }            
                else if (config.type?.toLowerCase() === xmlLabel?.toLowerCase())
                {
                    postImportXML(config.importConfigurationId, importFile, onData, onError);
                }
            }
            else if (config.action === convertLabel)
            {
                if (config.type?.toLowerCase() === xmlToExcelLabel?.toLowerCase())
                {
                    postConvertXmlToExcel(config.importConfigurationId, importFile, onData, onError);
                }
                else if (config.type?.toLowerCase() === excelToXmlLabel?.toLowerCase())
                {
                    postConvertExcelToXml(config.importConfigurationId, excelSheetName, importFile, onData, onError);
                }
            }
            resetImportFile();
        }
    };

    const handleClose = () => {
        resetImportFile();
        setOpenDropzoneArea(false);
    };

    const handleUpload = (rows) => {

        if(rows && rows.length)
        {
            if (rows.some(x => !!!x.brandId || !brands.some(y => y.id === x.brandId))) {
                setSnackBarInfo({open: true, message: "Cannot import because at least 1 selected import contains an empty brand", severity: "error"});
            } 
            else {
                setConfig(rows[0]);
                setOpenDropzoneArea(true);
            }
        }
    };

    const actions = [
        {
            label: "Import",
            name: "import",
            disableOnEmptySelection: true,
            onAction: handleUpload,
        }
    ];

    const acceptedFiles = 
        config.type?.toLowerCase() === excelLabel?.toLowerCase()
        || config.type?.toLowerCase() === excelToXmlLabel?.toLowerCase()
        ? ['.xlsx']
        : ['.xml'];

    return (
        <React.Fragment>
            <TemplateManage
                keyPropertyId="importConfigurationId"
                label="Import Config"
                title={name}
                titleIcon={icon()}
                columnDefs={ImportConfigurationColumnDefs(
                    createKeyValueObject(headers, "autocareHeaderId", "headerName"), 
                    createKeyValueObject(brands, "id", "name"))}
                createDefaultObject={createImportConfiguration}
                getAllObjects={getAllImportConfigs}
                postObject={postImportConfig}
                deleteObject={deleteImportConfig}
                additionalActions={actions}
                validations={validations}
                defaultSortColumn="importConfigurationName"          
                disableMultipleSelection
                renderInputs={({editValue, setEditValue, errors}) => {
                    const selectedAction = actionTypes.find(x => x.name === editValue.action) || actionTypes[0];

                    const selectedAutocareHeader = headers.find(x => x.autocareHeaderId === editValue.autocareHeaderId);
                    const selectedBrand = brands.find(x => x.id === editValue.brandId);

                    const availableFormatsType = selectedAction.name === convertLabel 
                        ? formatTypes.filter(x => x.id > 2)
                        : formatTypes.filter(x => x.id < 3);

                    const selectedFormat = availableFormatsType.find(x => x.name === editValue.type) || availableFormatsType[0];

                    return (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <GeneralTextField 
                                    keyPropertyName="importConfigurationName"
                                    label="Name"
                                    itemValue={editValue}
                                    setItemValue={v => setName(v, setEditValue)}
                                    handleError={ (p) => !!errors[p] }
                                    getHelperText={ (p) => errors[p] || '' }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GeneralAutocomplete 
                                    id="action-autocomplete"
                                    options={actionTypes}
                                    value={selectedAction}
                                    keyId="id"
                                    keyName="name"
                                    setId={v => handleSetAction(v, editValue, setEditValue)}
                                    label="Action"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GeneralAutocomplete 
                                    id="type-autocomplete"
                                    options={availableFormatsType}
                                    value={selectedFormat}
                                    keyId="id"
                                    keyName="name"
                                    setId={v => handleSetType(v, editValue, setEditValue)}
                                    label="Format"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GeneralVirtualizedAutocomplete 
                                    fullWidth
                                    label="Auto Care Xml Header"
                                    id="autocare-header-autocomplete"
                                    options={headers}
                                    value={selectedAutocareHeader ?? ''}
                                    keyId="autocareHeaderId"
                                    keyName="headerName"
                                    disabled={!isAutocareHeaderRequired(editValue)}
                                    onChange={(e, val) => handleSetSelectedAutocareHeader(val, editValue, setEditValue)}
                                    handleError={ (p) => !!errors[p] }
                                    getHelperText={ (p) => errors[p] || '' }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GeneralAutocomplete 
                                    keyPropertyName="brandId"
                                    id="brand-autocomplete"
                                    options={brands}
                                    value={selectedBrand}
                                    keyId="id"
                                    keyName="name"
                                    setId={v => handleSetBrandId(v, editValue, setEditValue)}
                                    label="Brand"
                                    handleError={ (p) => !!errors[p] }
                                    getHelperText={ (p) => errors[p] || '' }
                                />
                            </Grid>
                        </Grid>
                    )
                }}
            />
            <Dialog aria-labelledby="customized-dialog-title" fullWidth open={openDropzoneArea}>
                <DialogContent>
                    <DropzoneArea 
                        filesLimit = {1}
                        onDelete={resetImportFile}
                        maxFileSize={50000000}
                        showPreviewsInDropzone={true}
                        useChipsForPreview
                        previewGridProps={{container: { spacing: 1, direction: 'row' }}}
                        previewText="File Uploaded"
                        showAlerts={['error']}
                        acceptedFiles={acceptedFiles}
                        onChange={handleSetImportFileChange}
                    />
                    {
                    isExcelSheetNameRequired() ?
                        <Box paddingTop={2} paddingBottom={2}>
                            <GeneralVirtualizedAutocomplete 
                                id="type-autocomplete-excel-sheet-names"
                                options={excelSheetNames}
                                value={excelSheetNames.find(x => x.value === excelSheetName)}
                                keyId="id"
                                keyName="value"
                                textFieldInputProps={{sx : {width: '100%'}}}
                                setId={v => setExcelSheetName(v)}
                                label="Sheet Names"
                                handleError={ () => !canUpload() }
                                getHelperText={ () => !canUpload() ? 'Must select an excel sheet' : '' }
                            />
                        </Box> : null
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button 
                        onClick={handleStart} 
                        disabled={!canUpload()}
                        autoFocus
                    >
                        Start
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
        </React.Fragment>
    );
}

export default ManageImportConfiguration;