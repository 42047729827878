import React, {useState, useEffect, useContext} from "react";
import { 
    createFilter,
    createDescription,
    createArrayDescription,
    createKeyValueObject,
} from '../utilities';
import TemplateManage from "../TemplateManage";
import GeneralVirtualizedAutocomplete from "../GeneralVirtualizedAutocomplete";
import GeneralAutocomplete from "../GeneralAutocomplete";
import {createExportConfiguration} from './ExportConfigurationUtilities';
import { SnackBarContext} from '../../context';
import {
    getAllExportConfigs,
    postExportConfig,
    deleteExportConfig,
    getAllAutocareHeader,
    getAllBrands,
    getAllProductLines,
    getAllMfrLabels,
    getAllLifeCycles,
    getAllArticles,
    exportEXCEL,
    exportXML
} from '../../services/ApiService';
import {DateCell} from 'jnpsoft-react-utilities/dist/JnpTable/Commons';
import GeneralTextField from "../GeneralTextField";
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AcesMappingTypes } from '../../services/AcesMappingTypeService';

const types = [ {id: 1, name: 'EXCEL'}, {id: 2, name: 'XML'} ];

const ExportConfigurationColumnDefs = (headers, brands, prods, mfrLables, lifeCycles, articles) => ([
    {
        Header: 'Id',
        accessor: 'exportConfigurationId',
        maxWidth: 60,
    },
    {
        Header: 'Name',
        accessor: 'exportConfigurationName',
        width: 180,
    },
    {
        Header: 'Type',
        accessor: 'type',
        width: 180,
    },
    {
        Header: 'Autocare Header',
        accessor: 'autocareHeaderId',
        Cell: createDescription(headers),
		filter: createFilter(headers),
        width: 180,
    },
    {
        Header: 'Brand',
        accessor: 'brandId',
        Cell: createDescription(brands),
		filter: createFilter(brands),
        width: 180,
    },
    {
        Header: 'Export Custom',
        accessor: 'exportCustom',
        Cell: ({ value }) => value ? 'Yes' : 'No',
        width: 180,
    },
    {
        Header: 'Include Undefined Attributes',
        accessor: 'includeUndefinedAttributes',
        Cell: ({ value }) => value ? 'Yes' : 'No',
        width: 180,
    },
    {
        Header: 'Convert to Engine Base',
        accessor: 'convertToEngineBase',
        Cell: ({ value }) => value ? 'Yes' : 'No',
        width: 180,
    },
    {
        Header: 'Aces Mapping Type',
        accessor: 'xmlAcesMappingType',
        Cell: ({ value }) => AcesMappingTypes.find(x => x.value === value)?.label,
        width: 180,
    },
    {
        Header: 'Product Lines',
        accessor: 'productLineIds',
        Cell: createArrayDescription(prods),
		filter: createFilter(prods),
        width: 320,
    },
    {
        Header: 'MfrLabels',
        accessor: 'mfrLabelIds',
        Cell: createArrayDescription(mfrLables),
		filter: createFilter(mfrLables),
        width: 180,
    },
    {
        Header: 'LifeCycles',
        accessor: 'lifeCycleIds',
        Cell: createArrayDescription(lifeCycles),
		filter: createFilter(lifeCycles),
        width: 180,
    },
    {
        Header: 'Parts',
        accessor: 'articleIds',
        Cell: createArrayDescription(articles),
		filter: createFilter(articles),
    },
    {
        Header: 'Date',
        Cell: DateCell,
        accessor: 'date'
    }    
]);

const ManageExportConfiguration = ({name, icon}) => {

    const [headers, setHeaders] = useState([]);
    const [brands, setBrands] = useState([]);
    const [products, setProducts] = useState([]);
	const [mfrLabels, setMfrLabels] = useState([]);
	const [lifeCycles, setLifeCycles] = useState([]);
    const [articles, setArticles] = useState([]);

    const {setSnackBarInfo} = useContext(SnackBarContext);

    useEffect(() => {        
        getAllAutocareHeader(setHeaders);
        getAllBrands(setBrands);
        getAllProductLines(setProducts);
        getAllMfrLabels(setMfrLabels);
        getAllLifeCycles(setLifeCycles);
        getAllArticles(setArticles);
    },[]);

    const validations = [
        (exportConfiguration) => {
            const exportConfigurationNameError = exportConfiguration.exportConfigurationName && exportConfiguration.exportConfigurationName.length ? undefined : "Export Configuration name is required";
            return {exportConfigurationName: exportConfigurationNameError};
        },
        (exportConfiguration) => {
            const headerNameError = exportConfiguration?.autocareHeaderId ? undefined : "Autocare header is required";
            return {headerName: headerNameError}
        },
        (exportConfiguration) => {
            const brandError = exportConfiguration.brandId > 0 ? undefined : "Brand is required";
            return {brandId: brandError}
        }
    ]

    const setName = (name, setName) => setName({...name});

    const handleSetSelectedAutocareHeader = (autocareHeader, exportConfiguration, setExportConfiguration) => {
        setExportConfiguration({...exportConfiguration, autocareHeaderId: autocareHeader?.autocareHeaderId});
    }
    
    const handleSetBrandId = (id, exportConfiguration, setExportConfiguration) => {
        setExportConfiguration({...exportConfiguration, brandId: id});
    }

    const handleSetType = (id, exportConfiguration, setExportConfiguration) => {
        setExportConfiguration({...exportConfiguration, type: types.find(x => x.id === id).name});
    }

    const handleSetExportCustom = (value, exportConfiguration, setExportConfiguration) => {
        setExportConfiguration({...exportConfiguration, exportCustom: value});
    }

    const handleSetIncludeUndefinedAttributes = (value, exportConfiguration, setExportConfiguration) => {
        setExportConfiguration({...exportConfiguration, includeUndefinedAttributes: value});
    }

    const handleConvertToEngineBase = (value, exportConfiguration, setExportConfiguration) => {
        setExportConfiguration({...exportConfiguration, convertToEngineBase: value});
    }


    const handleSetProducts = (values, exportConfiguration, setExportConfiguration) => {
        setExportConfiguration({...exportConfiguration, productLineIds: values});
    }

    const handleSetMfrLabels = (values, exportConfiguration, setExportConfiguration) => {
        setExportConfiguration({...exportConfiguration, mfrLabelIds: values});
    }

    const handleSetLifeCycles = (values, exportConfiguration, setExportConfiguration) => {
        setExportConfiguration({...exportConfiguration, lifeCycleIds: values});
    }

    const handleSetArticles = (values, exportConfiguration, setExportConfiguration) => {
        setExportConfiguration({...exportConfiguration, articleIds: values});
    }

    const handleAcesMappingType = (value, exportConfiguration, setExportConfiguration) => {
        setExportConfiguration({...exportConfiguration, xmlAcesMappingType: value});
    }

    const handleExport = (rows) => {
        rows.forEach( (config) => {
            if(config.type === 'EXCEL') {
                exportEXCEL(config.exportConfigurationId, response => {
                    setSnackBarInfo({open: true, message: "Export(s) started", severity: "info"});
                });
            }
            else {
                exportXML(config.exportConfigurationId, response => {
                    setSnackBarInfo({open: true, message: "Export(s) started", severity: "info"});
                });
            }
        });
    }

    const actions = [
        {
            label: "Export",
            name: "export",
            disableOnEmptySelection: true,
            onAction: handleExport,
        }];

    const filterExportConfigurationOptions = (
            optionKeyName, 
            options, 
            articleKeyName, 
            articles, 
            {
                brandId, 
                productLineIds = [], 
                articleIds = [], 
                mfrLabelIds = [], 
                lifeCycleIds = []}) => {
        if (!((brandId && brandId > 0) || productLineIds.length || articleIds.length || mfrLabelIds.length || lifeCycleIds.length))
        {
            return options;
        }

        return options.filter(x => 
            articles.some(y => 
                x[optionKeyName] === y[articleKeyName]
                && (!brandId || y.brandId === brandId)
                && (optionKeyName === 'productLineId' || !productLineIds?.length || productLineIds.includes(y.productLineId))
                && (optionKeyName === 'articleId' || !articleIds?.length || articleIds.includes(y.articleId))
                && (optionKeyName === 'mfrLabelId' || !mfrLabelIds?.length || mfrLabelIds.includes(y.mfrLabelId))
                && (optionKeyName === 'lifeCycleId' || !lifeCycleIds?.length || lifeCycleIds.includes(y.lifeCycleId))
            )
        );
    }

    return (
        <TemplateManage
            keyPropertyId="exportConfigurationId"
            label="Export Config"
            title={name}
            titleIcon={icon()}
            columnDefs={ExportConfigurationColumnDefs(
                createKeyValueObject(headers, "autocareHeaderId", "headerName"), 
                createKeyValueObject(brands, "id", "name"), 
                createKeyValueObject(products, "productLineId", "productLineName"), 
                createKeyValueObject(mfrLabels, "mfrLabelId", "mfrLabelName"), 
                createKeyValueObject(lifeCycles, "lifeCycleId", "lifeCycleName"), 
                createKeyValueObject(articles, "articleId", "articleNumber"))}
            createDefaultObject={createExportConfiguration}
            getAllObjects={getAllExportConfigs}
            postObject={postExportConfig}
            deleteObject={deleteExportConfig}
            additionalActions={actions}
            validations={validations}
            defaultSortColumn="exportConfigurationName"
            renderInputs={({editValue, setEditValue, errors}) => 
            {

                const selectedAutocareHeader = headers.find(x => x.autocareHeaderId === editValue.autocareHeaderId);
                const selectedBrand = brands.find(x => x.id === editValue.brandId);
                const selectedProducts = products.filter(x => editValue.productLineIds.includes(x.productLineId));
                const selectedMfrLabels = mfrLabels.filter(x => editValue.mfrLabelIds.includes(x.mfrLabelId));
                const selectedLifecycles = lifeCycles.filter(x => editValue.lifeCycleIds.includes(x.lifeCycleId));
                const selectedArticles = articles.filter(x => editValue.articleIds.includes(x.articleId));
                const selectedType = types.find(x => x.name === editValue.type);
                const selectedAcesMappingType = AcesMappingTypes.find(x => x.value === editValue.xmlAcesMappingType) ?? AcesMappingTypes[0];

                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <GeneralTextField 
                                keyPropertyName="exportConfigurationName"
                                label="Name"
                                itemValue={editValue}
                                setItemValue={v => setName(v, setEditValue)}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralVirtualizedAutocomplete 
                                fullWidth
                                label="Auto Care Xml Header"
                                id="autocare-header-autocomplete"
                                options={headers}
                                value={selectedAutocareHeader ?? ''}
                                keyId="autocareHeaderId"
                                keyName="headerName"
                                onChange={(e, val) => handleSetSelectedAutocareHeader(val, editValue, setEditValue)}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralAutocomplete 
                                id="type-autocomplete"
                                options={types}
                                value={selectedType}
                                keyId="id"
                                keyName="name"
                                setId={v => handleSetType(v, editValue, setEditValue)}
                                label="Type"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralAutocomplete 
                                id="brand-autocomplete"
                                keyPropertyName="brandId"
                                options={brands}
                                value={selectedBrand}
                                keyId="id"
                                keyName="name"
                                setId={v => handleSetBrandId(v, editValue, setEditValue)}
                                label="Brand"
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralAutocomplete 
                                id="type-aces-mapping-type"
                                options={AcesMappingTypes}
                                value={selectedAcesMappingType ?? AcesMappingTypes[0]}
                                keyId="value"
                                keyName="label"
                                disabled={editValue.type === 'EXCEL'}
                                setId={v => handleAcesMappingType(v, editValue, setEditValue)}
                                label="Aces Xml Mapping Type"
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                            label="Export Custom"
                            control={
                                <Checkbox
                                    checked={editValue.exportCustom && editValue.type !== 'XML'}
                                    disabled={editValue.type === 'XML'}
                                    onChange={e => handleSetExportCustom(e.target.checked, editValue, setEditValue)}
                                    inputProps={{ 'aria-label': 'controlled-export-custom' }}
                                />
                            }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                            label="Convert to Engine Base"
                            control={
                                <Checkbox
                                    checked={editValue.convertToEngineBase}
                                    onChange={e => handleConvertToEngineBase(e.target.checked, editValue, setEditValue)}
                                    inputProps={{ 'aria-label': 'controlled-convert-to-engine-base' }}
                                />
                            }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                            label="Include Undefined Attributes (-, N/R, N/F & N/A)"
                            control={
                                <Checkbox
                                    checked={editValue.includeUndefinedAttributes}
                                    onChange={e => handleSetIncludeUndefinedAttributes(e.target.checked, editValue, setEditValue)}
                                    inputProps={{ 'aria-label': 'controlled-include-Undefined-Attributes' }}
                                />
                            }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralAutocomplete
                                multiple={true}
                                options={filterExportConfigurationOptions('productLineId', products, 'productLineId', articles, editValue)}
                                value={selectedProducts}
                                keyId="productLineId"
                                keyName="productLineName"
                                setId={v => handleSetProducts(v, editValue, setEditValue)}
                                label="Products"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralAutocomplete
                                multiple={true}
                                options={filterExportConfigurationOptions('mfrLabelId', mfrLabels, 'mfrLabelId', articles, editValue)}
                                value={selectedMfrLabels}
                                keyId="mfrLabelId"
                                keyName="mfrLabelName"
                                setId={v => handleSetMfrLabels(v, editValue, setEditValue)}
                                label="MfrLabels"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralAutocomplete
                                multiple={true}
                                options={filterExportConfigurationOptions('lifeCycleId', lifeCycles, 'lifeCycleId', articles, editValue)}
                                value={selectedLifecycles}
                                keyId="lifeCycleId"
                                keyName="lifeCycleName"
                                setId={v => handleSetLifeCycles(v, editValue, setEditValue)}
                                label="LifeCycles"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralAutocomplete
                                multiple={true}
                                options={filterExportConfigurationOptions('articleId', articles, 'articleId', articles, editValue)}
                                value={selectedArticles}
                                keyId="articleId"
                                keyName="articleNumber"
                                setId={v => handleSetArticles(v, editValue, setEditValue)}
                                label="Part #"
                            />
                        </Grid> 
                    </Grid>
                )
            }
        }
        />
    );
}

export default ManageExportConfiguration;