import {postAcesArticleLinkage} from './ApiService';

export const AcesAttributeMatchType = {
  Full: 'full',
  Partial: 'partial',
  Custom: 'custom',
  Invalid: 'invalid',
}

export const acesAttributeMatchTypeRender = {
  [AcesAttributeMatchType.Full]: {order: 0, render: `🟢`},
  [AcesAttributeMatchType.Partial]: {order: 1, render: `🟠`},
  [AcesAttributeMatchType.Custom]: {order: 2, render: `🟡`},
  [AcesAttributeMatchType.Invalid]: {order: 3, render: `🔴`}
};

export const isDisable = (group, linkage) => {
  return group.id !== 'equipement-base' 
          &&!(Boolean(linkage.equipmentModel)
          && Boolean(linkage.vehicleType)
          && Boolean(linkage.equipmentMfr))
};

export const handleSelect = (option, facetName, linkage, setLinkage, setIsLinkageCustom, facets, linkageMapper) => {
  const fieldTypeName = facets.find(x => x.name === facetName)?.properties?.FieldTypeName;
  const currentLinkage = !!linkageMapper ? {...linkageMapper(linkage)} : {...linkage};
  if (!Boolean(option) && (fieldTypeName === 'equipmentModel'
      || fieldTypeName === 'equipmentMfr'
      || fieldTypeName === 'vehicleType'))
  {
      facets.forEach(facet => {
          if (facet.properties['FieldTypeName'] !== 'equipmentModel'
              && facet.properties['FieldTypeName'] !== 'equipmentMfr'
              && facet.properties['FieldTypeName'] !== 'vehicleType')
          {
              currentLinkage[facet.properties['FieldTypeName']] = {
                  table : facet.properties['SourceName'],
                  id :  0,
                  value : undefined
              };
          }
      });
  }
  else if (Boolean(option) && (fieldTypeName === 'equipmentModel'
  || fieldTypeName === 'equipmentMfr'
  || fieldTypeName === 'vehicleType') && option.value !== currentLinkage[fieldTypeName]?.value) {
      facets.forEach(facet => {
          if (facet.properties['FieldTypeName'] !== 'equipmentModel'
              && facet.properties['FieldTypeName'] !== 'equipmentMfr'
              && facet.properties['FieldTypeName'] !== 'vehicleType')
          {
              currentLinkage[facet.properties['FieldTypeName']] = {
                  table : facet.properties['SourceName'],
                  id :  0,
                  value : undefined
              };
          }
      });
  }
  const newLinkage = {...currentLinkage, [fieldTypeName]: option};
  const isCustom = facets.filter(facet => newLinkage[facet.properties['FieldTypeName']]?.isCustom).length > 0;
  setIsLinkageCustom(isCustom);

  if (!!linkageMapper) {
    setLinkage({...linkage, acesArticleLinkage: newLinkage});
  } else {
    setLinkage(newLinkage);
  }
}

export const doPostLinkage = (linkage, resolve, facets) => {
  facets.forEach(facet => {
      if (!Boolean(linkage[facet.properties['FieldTypeName']]))
      {
          linkage[facet.properties['FieldTypeName']] = {
              table : facet.properties['SourceName'],
              id :  0,
              value : undefined
          };
      }
  });

  postAcesArticleLinkage(linkage, resolve)
}