import React from "react";
import { getAllMfrLabels, postMfrLabel, deleteMfrLabel } from '../../services/ApiService';
import TemplateManage from "../TemplateManage";
import {DateCell} from 'jnpsoft-react-utilities/dist/JnpTable/Commons';
import Grid from '@mui/material/Grid';
import GeneralTextField from "../GeneralTextField";

const mfrLabelColumnDefs = [
    {
        Header: 'Id',
        accessor: 'mfrLabelId',
        maxWidth: 60,
    },
    {
        Header: 'Mfr Label',
        accessor: 'mfrLabelName'
    },
    {
        Header: 'Date',
        Cell: DateCell,
        accessor: 'date'
    } 
];

const createMfrLabel = (mfrLabelId = -1, mfrLabelName = '') => ({
    mfrLabelId,
    mfrLabelName
});

const ManageMfrLabel = ({name, icon}) => {

    const setMfrLabel = (mfrLabel, setMfrLabel) => setMfrLabel({...mfrLabel});

    const validations = [
        (mfrLabel) => {
            const error = mfrLabel.mfrLabelName && mfrLabel.mfrLabelName.length ? undefined : "Mfr Label name is required";
            return {mfrLabelName: error};
        }
    ];

    return (
        <TemplateManage
            keyPropertyId="mfrLabelId"
            label="Mfr Label"
            title={name}
            titleIcon={icon()}
            columnDefs={mfrLabelColumnDefs}
            createDefaultObject={createMfrLabel}
            getAllObjects={getAllMfrLabels}
            postObject={postMfrLabel}
            deleteObject={deleteMfrLabel}
            validations={validations}
            uniqueId="manage_mfr_label"
            defaultSortColumn="mfrLabelName"
            renderInputs={({editValue, setEditValue, errors}) => 
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <GeneralTextField 
                            keyPropertyName="mfrLabelName"
                            label="Name"
                            itemValue={editValue}
                            setItemValue={v => setMfrLabel(v, setEditValue)}
                            handleError={ (p) => !!errors[p] }
                            getHelperText={ (p) => errors[p] || '' }
                        />
                    </Grid>
                </Grid>
            }
        />
    );
};

export default ManageMfrLabel;