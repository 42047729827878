import React, {useState, useEffect} from "react";
import { getAllProductLines, postProductLine, deleteProductLine, getAllPartTypes } from '../../services/ApiService';
import TemplateManage from "../TemplateManage";
import {createDefaultProductLine} from "./product-uilities";
import GeneralVirtualizedAutocomplete from "../GeneralVirtualizedAutocomplete";
import GeneralTextField from "../GeneralTextField";
import Grid from '@mui/material/Grid';
import {DateCell} from 'jnpsoft-react-utilities/dist/JnpTable/Commons';

const productLineColumnDefs = [
    {
        Header: 'Id',
        accessor: 'productLineId',
        maxWidth: 60,
    },
    {
        Header: 'Product Line',
        accessor: 'productLineName'
    },
    {
        Header: 'Part Type ID',
        width: 130,
        accessor:'partTypeId',
    },
    {
        Header: 'Part Type',
        width: 400,
        accessor:'partTypeName',
    },
    {
        Header: 'Date',
        Cell: DateCell,
        accessor: 'date'
    } 
];

const ManageProductLine = ({name, icon}) => {

    const [partTypes, setPartTypes] = useState([]);

    const validations = [
        (product) => {
            const productLineNameError = product.productLineName && product.productLineName.length ? undefined : "Product line name is required";
            return {productLineName: productLineNameError};
        },
        (product) => {
            const partTypeNameError = product?.partTypeId ? undefined : "Part type is required";
            return {partTypeName: partTypeNameError}
        }
    ]

    const handleSetProductLineName = (product, setProduct) => {
        setProduct({...product});
    }

    const handleSetPartTypeId = (partType, product, setProduct) => {
        setProduct({...product, partTypeId: partType?.partTypeId, productLineName: partType?.partTypeName || ''});
    }

    useEffect(() => {        
        getAllPartTypes(setPartTypes);
    },[]);

    return (
        <TemplateManage
            keyPropertyId="productLineId"
            label="Product Line"
            columnDefs={productLineColumnDefs}
            createDefaultObject={createDefaultProductLine}
            getAllObjects={getAllProductLines}
            deleteObject={deleteProductLine}
            postObject={postProductLine}
            validations={validations}
            defaultSortColumn="productLineName"
            title={name}
            titleIcon={icon()}
            renderInputs={({editValue, setEditValue, errors}) => 
            {
                const selectedPartType = partTypes.find(x => x.partTypeId === editValue.partTypeId);
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <GeneralVirtualizedAutocomplete 
                                    fullWidth
                                    label="Part Type"
                                    id="part-type-autocomplete"
                                    options={partTypes}
                                    value={selectedPartType ?? ''}
                                    keyId="partTypeId"
                                    keyName="partTypeName"
                                    onChange={(e, val) => handleSetPartTypeId(val, editValue, setEditValue)}
                                    handleError={ (p) => !!errors[p] }
                                    getHelperText={ (p) => errors[p] || '' }
                                    />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralTextField 
                                keyPropertyName="productLineName"
                                label="Name"
                                itemValue={editValue}
                                setItemValue={v => handleSetProductLineName(v, setEditValue)}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                    </Grid>
                )
            }
        }/>
    );
};

export default ManageProductLine;