import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import LinkageOverview from './LinkageOverview';

import {
    getArticleForId,
    getSearchFacets,
    getAcesArticleLinkagesForArticleId,
    getProductLineForId,
    getPositionForId,
    deleteAcesLinkages
} from '../../services/ApiService';
import { createColumnDefs } from './ColumnDefinitions';
import TemplateManage from '../TemplateManage';
import LinkIcon from '@mui/icons-material/Link';
import {isDisable, handleSelect, acesAttributeMatchTypeRender, doPostLinkage} from '../../services/LinkageOverviewService';

const ManageLinkage = () => {

    const [article, setArticle] = useState({});
    const { articleId } = useParams();
    const [facets, setFacets] = useState([]);
    const [productLine, setProductLine] = useState({});
    const [positions, setPositions] = useState({});
    const [isLinkageCustom, setIsLinkageCustom] = useState(false);

    useEffect(() => {
        getArticleForId(articleId, setArticle);
        getSearchFacets(data => {
            setFacets(data);
        });
    }, []);

    useEffect(() => {
        if (article && article.productLineId)
        {
            getProductLineForId(article.productLineId, setProductLine);
        }
    }, [article]);

    useEffect(() => {
        if (productLine && productLine.partTypeId)
        {
            getPositionForId(productLine.partTypeId, (positions) => {
                setPositions(positions.reduce(addNameByIdToDict, {}));
            });
        }
    }, [productLine]);

    const refreshPosition = (onData) => {
        getPositionForId(productLine.partTypeId, (positions) => {
            setPositions(positions.reduce(addNameByIdToDict, {}));
            onData();
        });
    }

    const addNameByIdToDict = (dict, pos) => {
        dict[pos.id] = pos.name;
        return dict;
    };

    const onEditValue = editValue => setIsLinkageCustom(facets.filter(facet => editValue[facet.properties['FieldTypeName']]?.isCustom).length > 0 || editValue.positionId > 100000)

    const columnDefinitions = createColumnDefs(positions || []);

    const validations = [
        (linkage) => {
            const equipmentModelError = Boolean(linkage.equipmentModel) ? undefined : "Equipment model is required";
            return {'equipment-model': equipmentModelError};
        },
        (linkage) => {
            const equipmentMfrError = Boolean(linkage.equipmentMfr) ? undefined : "Equipment mfr is required";
            return {'mfr': equipmentMfrError};
        },
        (linkage) => {
            const vehicleTypeError = Boolean(linkage.vehicleType) ? undefined : "Vehicle type is required";
            return {'vehicle-type': vehicleTypeError};
        },
        (linkage) => {
            const positionError = Boolean(linkage.positionId) ? undefined : "Position is required";
            return {'position': positionError};
        }
    ];

    const label = () => isLinkageCustom ? <div style={{display: 'flex'}}>Application&nbsp;<div style={{backgroundColor: 'yellow'}}> (Custom)</div></div> : "Application"
    
    return (
        <TemplateManage
            keyPropertyId="id"
            label={label}
            saveMessageLabel="Application"
            title={article.articleNumber}
            titleIcon={<LinkIcon />}
            columnDefs={columnDefinitions}
            onEditValue={onEditValue}
            createDefaultObject={() => ({articleId: article.articleId, quantity: 1, notes: []})}
            getAllObjects={(resolve) => new Promise(() => getAcesArticleLinkagesForArticleId(articleId, resolve))}
            postObject={(value, resolve) => new Promise(() => doPostLinkage(value, resolve, facets))}
            deleteObject={deleteAcesLinkages}
            validations={validations}
            uniqueId="manage_linkages_new"
            defaultSortColumn='Equipment Model'
            customRowProps={row => ({
                    style: {
                        backgroundColor: row.original.isCustom || row.original.positionId > 100000 ? 'yellow' : null,
                    }
                })
            }
            renderInputs={({editValue, setEditValue, errors}) => {

                const notes = editValue.notes && editValue.notes.length ? [...editValue.notes] : [''];
               
                return (
                    <LinkageOverview
                        isDisable={group => isDisable(group, editValue)}
                        errors={errors}
                        linkage={editValue}
                        facets={facets}
                        setIsLinkageCustom={setIsLinkageCustom}
                        refreshPosition={refreshPosition}
                        handleSelect={(option, facetName) => handleSelect(option, facetName, editValue, setEditValue, setIsLinkageCustom, facets)}
                        acesAttributeMatchTypeRender={acesAttributeMatchTypeRender}
                        notes={notes}
                        setNotes={notes => setEditValue({...editValue, notes})}
                        positions={positions}
                        positionId={editValue.positionId}
                        setPositionId={positionId => setEditValue({...editValue, positionId})}
                        quantity={editValue.quantity}
                        setQuantity={quantity => setEditValue({...editValue, quantity})}
                    />
                    )
                }
            }
        />
    );
}

export default ManageLinkage;