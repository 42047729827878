import React, {useState, useMemo, useEffect} from 'react';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Grid from "@material-ui/core/Grid"
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@mui/material/Alert';

import ActionBar from './ActionBar';
import { HomeLink, Links, Routes } from './routes';

import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";

import { SnackBarContext, ActionBarContext, EditBarContext } from '../context';
import { setToLocalStorage, getToLocalStorage } from '../services/InternalStorageService';
import { MainSideBar, Notification } from '../constants/InternalStorageKeys';
import { EditBarWidth } from './EditBar';
import MyProducts from './article/MyProducts';
import {useHub, useClientMethod} from 'react-use-signalr';
import {signalRConnection} from '../services/NotificationHubService';
import DrawerLinkButton from './DrawerLinkButton';
import {convertISOtoLocal} from 'jnpsoft-react-utilities/dist/JnpTable/Commons';

const DrawerWidth = 300;
const zIndexDrawerMenu = 1200;
const FooterHeight = 35;

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		overflowY: 'hidden',
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	content: {
		height: `calc(100% - ${FooterHeight + 30}px)`,
		maxHeight: `calc(100% - ${FooterHeight + 30}px)`,
		marginLeft: theme.spacing(7) + 1,
		width: `calc(100% - ${theme.spacing(7) + 1}px)`,
		padding: '8px',

		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	contentShiftLeft: {
		height: `calc(100% - ${FooterHeight + 30}px)`,
		maxHeight: `calc(100% - ${FooterHeight + 30}px)`,
		marginLeft: DrawerWidth,
		width: `calc(100% - ${DrawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	contentShiftRight: {
		height: `calc(100% - ${FooterHeight + 30}px)`,
		maxHeight: `calc(100% - ${FooterHeight + 30}px)`,
		marginRight: EditBarWidth,
		width: `calc(100% - ${EditBarWidth + theme.spacing(7) + 1}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	contentShiftLeftRight: {
		height: `calc(100% - ${FooterHeight + 30}px)`,
		maxHeight: `calc(100% - ${FooterHeight + 30}px)`,
		marginLeft: DrawerWidth,
		marginRight: 450,
		width: `calc(100% - ${(DrawerWidth + EditBarWidth)}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawer: {
		width: DrawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		zIndex:zIndexDrawerMenu,
	},
	drawerOpen: {
		width: DrawerWidth,
		backgroundColor: '#F5F5F5',
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	
	drawerClose: {
		width: theme.spacing(7) + 1,
		backgroundColor: '#F5F5F5',
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),	
		
		"& span.MuiTypography-displayBlock": {
			display:"none"
		},
		"& div.MuiListItemIcon-root": {
			minWidth:"0px",
		}
		
	},
	
	chevronIcon: {
		textAlign: "center"
	},
	chevronIconOpen: {
		textAlign: "right"
	},
	footer: {
		width: `100%`,
		zIndex: `${zIndexDrawerMenu +1}`,
		backgroundColor:"#003061",
		position:"absolute",
		left:0,
		bottom:0,
		color:"white",
		height:`${FooterHeight}px`,
		padding: "0.5rem",
		fontFamily: "Open Sans, Arial, Helvetica, Sans-Serif",
		fontSize:"13px",
		'& a': {
			textDecoration:"none",
			color:"inherit",
			'&:hover': {
				textDecoration:"underline"
			}
		}
	},

	footerNavBar:{
		"& span": {
			padding: "0 0.5rem"
		}
	}
}));

const Main = (props) => {
	const classes = useStyles();

	const initialOpen = Boolean(getToLocalStorage(MainSideBar)) ?? false;

	const [open, setOpen] = useState(initialOpen);

	const [snackBarInfo, setSnackBarInfo] = useState({open: false, message: "", severity: "info"});
	const [actionInfos, setActionInfos] = useState({actions: [], actionClicked: null});
	const [userNotifications, setUserNotifications] = useState([]);
	const [editBarOpen, setEditBarOpen] = useState(false);
	const [actionStaticContent, setActionStaticContent] = useState(null);
	
	const {hubConnectionState, error} = useHub(signalRConnection);

	useEffect(() => {
		if (hubConnectionState === 'Connected') {
			console.log('Connected to notification service!');
		}
	},[hubConnectionState, error]);

	useEffect(() => {
		if (props) {
			const userId = props.username ?? '_';
			const userNotifications = getToLocalStorage(`${userId}-${Notification}`) ?? [];
			setUserNotifications([...userNotifications]);
		}
	}, [props.username])

	useEffect(() => {
		const userId = props.username ?? '_';
		setToLocalStorage(`${userId}-${Notification}`, userNotifications);
	},[userNotifications]);

	useClientMethod(signalRConnection, "ReceiveNotifications", (username, message) => {
		const newNotifications = [...userNotifications, `${message} | ${convertISOtoLocal(new Date(Date.now()).toISOString())}`];
		setUserNotifications(newNotifications);
	});

	const snackBarContextValue = useMemo(() => ({
		snackBarInfo,
		setSnackBarInfo,
	}),[snackBarInfo]);

	const actionBarContextValue = useMemo(() => ({
		actionInfos,
		setActionInfos,
		actionStaticContent,
		setActionStaticContent,
	}),[actionInfos, actionStaticContent]);

	const editBarContextValue = useMemo(() => ({
		editBarOpen,
		setEditBarOpen,
	}),[editBarOpen]);

	const handleOpen = e => {
		const newOpenValue = !open;
		setOpen(newOpenValue);
		setToLocalStorage(MainSideBar, newOpenValue);
	};
	const handleSnackBarOnClose = () => setSnackBarInfo({...snackBarInfo, open: false, message: ""});

    return (
		<SnackBarContext.Provider value={snackBarContextValue}>
				<div className={classes.root}>
					<CssBaseline />
					<Router>
						<Drawer 
							variant="permanent"
							className={clsx(classes.drawer, {
								[classes.drawerOpen]: open,
								[classes.drawerClose]: !open,
							})}
							classes={{
								paper: clsx({
								[classes.drawerOpen]: open,
								[classes.drawerClose]: !open,
								}),
							}}
						>					
							<div className={classes.toolbar}></div>
							<div className={clsx(classes.chevronIcon, {
								[classes.chevronIconOpen]: open
							})}>
								<IconButton  onClick={handleOpen}>
								{
									open ? <ChevronLeftIcon/> : <ChevronRightIcon/>
								}
								</IconButton>
							</div>
							<List>
									<DrawerLinkButton linkInfo={HomeLink} />

							{
								Links.map((x, i) => <DrawerLinkButton key={i} linkInfo={x} />)
							}
							</List>
						</Drawer>
						<EditBarContext.Provider value={editBarContextValue}>
						<ActionBarContext.Provider value={actionBarContextValue}>
							<main 
								className={clsx(classes.content,{
									[classes.contentShiftLeft]: open && !editBarOpen,
									[classes.contentShiftRight]: !open && editBarOpen,
									[classes.contentShiftLeftRight]: open && editBarOpen,
								})}>
								<ActionBar 
									actionInfos={actionInfos} 
									setActionInfos={setActionInfos} 
									notifications={userNotifications}
									setNotifications={setUserNotifications}
									staticContent={actionStaticContent}/>
								<Switch> 
									{
										Routes.map((r,i) => <Route key={i} path={r.route}>{r.target && r.target({...r, userNotifications})}</Route>)
									}
									{
										Links.map((l,i) => <Route key={i} path={`/${l.route}`}>{ l.target && l.target({...l, userNotifications}) }</Route>)
									}
									<Route path={`/${HomeLink.route}`}>
										<MyProducts />
									</Route>
								</Switch>
							</main>
						</ActionBarContext.Provider>
						</EditBarContext.Provider>
						<Grid container className={classes.footer}>
							<Grid item xs={3}>
								<span><a href="https://jnpsoft.com/" target="_blank">© {new Date().getFullYear()} JNPSoft</a></span>
							</Grid>
							<Grid item xs={9} align="right" className={classes.footerNavBar}>
								<span><a href="https://www.tecalliance.net/en/" target="_blank" rel="noreferrer">TecAlliance</a></span><span> | </span>
								<span><a href="https://jnpsoft.com/about-us/" target="_blank" rel="noreferrer">About us</a></span><span> | </span>
								<span><a href="https://jnpsoft.com/privacy-policy-of-jnpsoft/" target="_blank" rel="noreferrer">Privacy statement</a></span><span> | </span> 
								<span><a href="https://jnpsoft.com/request-a-callback/" target="_blank" rel="noreferrer">Contact</a></span>
							</Grid>
						</Grid>
					</Router>
					<Snackbar open={snackBarInfo.open} autoHideDuration={7000} onClose={handleSnackBarOnClose}>
						<Alert 
							onClose={handleSnackBarOnClose}
							severity={snackBarInfo.severity || 'info'}
							variant="filled">
							{snackBarInfo.message}
						</Alert>
					</Snackbar>
				</div>
		</SnackBarContext.Provider>
    );
}

export default Main;