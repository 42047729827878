import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Chip from '@mui/material/Chip';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const GeneralAutocomplete = (props) => {
    const {
        keyId,
        keyName,
        label,
        setId,
        handleError = () => false,
        getHelperText = () => '',
        keyPropertyName = keyName,
        multiple = false,
        value,
        options,
        getOptionLabel = (option) => {
          return option[keyName] || '';
        },
        textFieldInputProps = {},
        ...otherParams
    } = props;

    const handleOnChange = (e, val, r) => {
        if (multiple && Array.isArray(value))
        {
            setId(val.map(x => x[keyId]));
        }
        else 
        {
          setId(val && options.filter ? val[keyId] : undefined);
        }
    };

    return (
        <Autocomplete 
            getOptionLabel={getOptionLabel}
            onChange={handleOnChange}
            multiple={multiple}
            options={options.sort((a, b) => `${a[keyName]}`.localeCompare(`${b[keyName]}`, undefined, {numeric: true}))}
            disableCloseOnSelect={multiple}
            isOptionEqualToValue={(option, value) => !Boolean(value) || option[keyId] == value[keyId]}
            renderOption={(props, option, { selected }) => (
                <li {...props} style={{fontSize: '0.8em'}}>
                  {
                    multiple ?
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />: undefined
                  }
                  {getOptionLabel(option)}
                </li>
              )}
            {...otherParams}
            value={value ?? ''}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={getOptionLabel(option)}
                    size="small"
                    {...getTagProps({ index })}
                    sx={{
                        minWidth: 240,
                        justifyContent: 'space-between'
                    }}
                  />
                ))
              }
            limitTags={5}
            renderInput={({InputLabelProps, InputProps, ...otherInputParams}) => (
                    <TextField
                        {...otherInputParams}
                        InputLabelProps={{...InputLabelProps, shrink: true }}
                        label={label}
                        variant='standard'
                        InputProps={{...InputProps, ...textFieldInputProps}}
                        error={handleError(keyPropertyName)}
                        helperText={getHelperText(keyPropertyName)}/>)
            }/>
    );
};

export default GeneralAutocomplete;