import React from 'react';
import Main from './components/Main';

import {AuthProvider} from 'jnpsoft-react-utilities/dist/AppBar';

const App = () => {
  return (
    <AuthProvider showUtilityBar={false}>
        <Main />
    </AuthProvider>
  );
}

export default App;