export const createImportConfiguration = (
    importConfigurationId = -1,
    importConfigurationName = '',
    action = 'Convert',
    type = 'EXCEL',
    autocareHeaderId = 0,
    brandId = 0) => ({
    importConfigurationId,
    importConfigurationName,
    action,
    type,
    autocareHeaderId,
    brandId
});