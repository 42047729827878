import React, {useState, useEffect} from 'react';
import TemplateManage from "../TemplateManage";
import Grid from '@mui/material/Grid';
import GeneralTextField from "../GeneralTextField";
import GeneralDatePicker from "../GeneralDatePicker";
import GeneralAutocomplete from '../GeneralAutocomplete';
import {
    getAllAutocareHeader,
    postAutocareHeader,
    deleteAutocareHeader,
    getAllRegion,
    getVcdbVersion,
    getPcdbVersion
} from '../../services/ApiService';
import {DateCell} from 'jnpsoft-react-utilities/dist/JnpTable/Commons';


const autocareHeaderColumnDefs = [
    {
        Header: 'Id',
        accessor: 'autocareHeaderId',
        maxWidth: 60,
    },
    {
        Header: 'Header Name',
        accessor: 'headerName'
    },
    {
        Header: 'Company Info',
        accessor: 'companyInfo'
    },
    {
        Header: 'Sender Name',
        accessor: 'senderName'
    },
    {
        Header: 'Sender Phone',
        accessor: 'senderPhone'
    },
    {
        Header: 'Transfer Date',
        accessor: 'transferDate',
        Cell: props => DateCell({...props, value: new Date(props.value).toISOString()}),
    },
    {
        Header: 'Mfr Code',
        accessor: 'mfrCode'
    },
    {
        Header: 'Document Title',
        accessor: 'documentTitle',
    },
    {
        Header: 'Effective Date',
        accessor: 'effectiveDate',
        Cell: DateCell,
    },
    {
        Header: 'Approved For',
        accessor: 'approvedFor'
    },
    {
        Header: 'Parts Approved For',
        accessor: 'partsApprovedFor'
    },
    {
        Header: 'Region For',
        accessor: 'regionFor'
    },
    {
        Header: 'Submission Type',
        accessor: 'submissionType'
    },
    {
        Header: 'Mapper Company',
        accessor: 'mapperCompany'
    },
    {
        Header: 'Mapper Contact',
        accessor: 'mapperContact'
    },
    {
        Header: 'Mapper Phone',
        accessor: 'mapperPhone'
    },
    {
        Header: 'Mapper Email',
        accessor: 'mapperEmail'
    },
    {
        Header: 'Vcdb Version Date',
        accessor: 'vcdbVersionDate',
        Cell: props => DateCell({...props, value: new Date(props.value).toISOString()}),
    },
    {
        Header: 'Qdb Version Date',
        accessor: 'qdbVersionDate',
        Cell: props => DateCell({...props, value: new Date(props.value).toISOString()}),
    },
    {
        Header: 'Pcdb Version Date',
        accessor: 'pcdbVersionDate',
        Cell: props => DateCell({...props, value: new Date(props.value).toISOString()}),
    }
    
];

const submissionTypes = [{id: 1, value: 'FULL'}, {id: 2, value: 'TEST'}];

const isNumericalRegEx = new RegExp('^\\d+$');

const ManageAutocareHeader = ({name, icon}) => {

    const [regions, setRegions] = useState([]);
    const [vcdbDate, setVcdbDate] = useState(0);
    const [pcdbDate, setPcdbDate] = useState(0);

    useEffect(() => {        
        getAllRegion(setRegions);
        getVcdbVersion(data => setVcdbDate(new Date(data.date).getTime()));
        getPcdbVersion(data => setPcdbDate(new Date(data.date).getTime()));
    },[]);

    const createAutocareHeader = (autocareHeaderId = -1, headerName = '', vcdbVersionDate = vcdbDate, qdbVersionDate = vcdbDate, pcdbVersionDate = pcdbDate) => ({
        autocareHeaderId,
        headerName,
        vcdbVersionDate,
        qdbVersionDate,
        pcdbVersionDate
    });

    const setAutocareHeader = (autocareHeader, setAutocareheader) => setAutocareheader({...autocareHeader});

    const validations = [
        (autocareHeader) => {
            const error = autocareHeader.headerName && autocareHeader.headerName.length ? undefined : "Autocare Header name is required";
            return {headerName: error};
        },
        (autocareHeader) => {
            const error = autocareHeader.effectiveDate !== undefined && isNumericalRegEx.test(autocareHeader.effectiveDate) ? undefined : "Effective date name is required";
            return {effectiveDate: error};
        },
        (autocareHeader) => {
            const error = autocareHeader.transferDate !== undefined && isNumericalRegEx.test(autocareHeader.transferDate) ? undefined : "Transfer date name is required";
            return {transferDate: error};
        },
        (autocareHeader) => {
            const error = autocareHeader.vcdbVersionDate !== undefined && isNumericalRegEx.test(autocareHeader.vcdbVersionDate) ? undefined : "Vcdb Version date name is required";
            return {vcdbVersionDate: error};
        },
        (autocareHeader) => {
            const error = autocareHeader.qdbVersionDate !== undefined && isNumericalRegEx.test(autocareHeader.qdbVersionDate) ? undefined : "Qdb date date name is required";
            return {qdbVersionDate: error};
        },
        (autocareHeader) => {
            const error = autocareHeader.pcdbVersionDate !== undefined && isNumericalRegEx.test(autocareHeader.pcdbVersionDate) ? undefined : "Pcdb date name is required";
            return {pcdbVersionDate: error};
        },
    ]

    const handleAutocareHeader = (propertyName, value, autocareHeader, setAutocareHeader) => {
        setAutocareHeader({...autocareHeader, [propertyName]: value});
    }

    return (
        <TemplateManage
            keyPropertyId="autocareHeaderId"
            label="Header"
            title={name}
            titleIcon={icon()}
            columns={autocareHeaderColumnDefs}
            createDefaultObject={createAutocareHeader}
            getAllObjects={getAllAutocareHeader}
            postObject={postAutocareHeader}
            deleteObject={deleteAutocareHeader}
            validations={validations}
            defaultSortColumn="headerName"
            renderInputs={({editValue, setEditValue, errors}) => {
                
                const selectedApprovedForRegion = regions.filter(x => editValue.approvedFor?.includes(x.value)) || [];
                const selectedPartsApprovedForRegion = regions.filter(x => editValue.partsApprovedFor?.includes(x.value)) || [];
                const selectedForRegion = regions.filter(x => editValue.regionFor?.includes(x.value)) || [];
                const selectedSubmissionType = submissionTypes.find(x => editValue.submissionType === x.value) || [];
                
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <GeneralTextField 
                                keyPropertyName="headerName"
                                label="Name"
                                itemValue={editValue}
                                setItemValue={v => setAutocareHeader(v, setEditValue)}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralTextField 
                                keyPropertyName="companyInfo"
                                label="Company Info"
                                itemValue={editValue}
                                setItemValue={v => setAutocareHeader(v, setEditValue)}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralTextField 
                                keyPropertyName="senderName"
                                label="Sender Name"
                                itemValue={editValue}
                                setItemValue={v => setAutocareHeader(v, setEditValue)}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralTextField 
                                keyPropertyName="senderPhone"
                                label="Sender Phone"
                                itemValue={editValue}
                                setItemValue={v => setAutocareHeader(v, setEditValue)}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralDatePicker 
                                keyPropertyName="transferDate"
                                label="Transfer Date"
                                itemValue={editValue}
                                setItemValue={v => setAutocareHeader(v, setEditValue)}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralDatePicker 
                                keyPropertyName="effectiveDate"
                                label="Effective Date"
                                itemValue={editValue}
                                setItemValue={v => setAutocareHeader(v, setEditValue)}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
    {/*                     <Grid item xs={12}>
                            <GeneralTextField 
                                keyPropertyName="mfrCode"
                                label="Mfr Code"
                                itemValue={editValue}
                                setItemValue={v => setAutocareHeader(v, setEditValue)}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid> */}
                        <Grid item xs={12}>
                            <GeneralAutocomplete
                                options={regions}
                                value={selectedApprovedForRegion}
                                label="Approved For"
                                keyId="value"
                                keyName="value"
                                setId={v => handleAutocareHeader("approvedFor", v, editValue, setEditValue)}
                                multiple={true}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralAutocomplete
                                options={regions}
                                value={selectedPartsApprovedForRegion}
                                label="Parts Approved For"
                                keyId="value"
                                keyName="value"
                                setId={v => handleAutocareHeader("partsApprovedFor", v, editValue, setEditValue)}
                                multiple={true}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralAutocomplete
                                options={regions}
                                value={selectedForRegion}
                                label="Region For"
                                keyId="value"
                                keyName="value"
                                setId={v => handleAutocareHeader("regionFor", v, editValue, setEditValue)}
                                multiple={true}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid> 
                        <Grid item xs={12}>
                            <GeneralTextField 
                                keyPropertyName="documentTitle"
                                label="Document Title"
                                itemValue={editValue}
                                setItemValue={v => setAutocareHeader(v, setEditValue)}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralAutocomplete 
                                options={submissionTypes}
                                value={selectedSubmissionType}
                                label="Submission Type"
                                keyId="value"
                                keyName="value"
                                setId={v => handleAutocareHeader("submissionType", v, editValue, setEditValue)}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralTextField 
                                keyPropertyName="mapperCompany"
                                label="Mapper Company"
                                itemValue={editValue}
                                setItemValue={v => setAutocareHeader(v, setEditValue)}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralTextField 
                                keyPropertyName="mapperContact"
                                label="Mapper Contact"
                                itemValue={editValue}
                                setItemValue={v => setAutocareHeader(v, setEditValue)}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralTextField 
                                keyPropertyName="mapperPhone"
                                label="Mapper Phone"
                                itemValue={editValue}
                                setItemValue={v => setAutocareHeader(v, setEditValue)}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralTextField 
                                keyPropertyName="mapperEmail"
                                label="Mapper Email"
                                itemValue={editValue}
                                setItemValue={v => setAutocareHeader(v, setEditValue)}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralDatePicker 
                                keyPropertyName="vcdbVersionDate"
                                label="VCDB Version Date"
                                itemValue={editValue}
                                setItemValue={v => setAutocareHeader(v, setEditValue)}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralDatePicker 
                                keyPropertyName="qdbVersionDate"
                                label="QDB Version Date"
                                itemValue={editValue}
                                setItemValue={v => setAutocareHeader(v, setEditValue)}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralDatePicker 
                                keyPropertyName="pcdbVersionDate"
                                label="PCDB Version Date"
                                itemValue={editValue}
                                setItemValue={v => setAutocareHeader(v, setEditValue)}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                    </Grid>
                )}
            }
        />
    );
};

export default ManageAutocareHeader;
