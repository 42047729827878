import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import Menu from '@mui/material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles(() => ({
    actionGridContent: {
        paddingBottom: '8px',
    },
}));

const ActionBar = ({actionInfos, setActionInfos, notifications, setNotifications, staticContent}) => {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl) && Boolean(notifications.length);

    const handleNotificationsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleNotificationsClose = () => {
        setAnchorEl(null);
    };

    const handleOnClick = name => setActionInfos({...actionInfos, actionClicked: name});

    const handleDeleteNotification = i => {
        if (i < notifications.length) {
            const newNotifications = [...notifications]
            newNotifications.splice(i, 1)
            setNotifications(newNotifications);
            
            if (!newNotifications.length)
            {
                handleNotificationsClose();
            }
        }
    }

    const badgeContent = notifications?.length ?? 0;
    const notificationIcon = badgeContent ? <NotificationsActiveIcon /> : <NotificationsIcon />;

    return (
        <React.Fragment>
        <Grid container className={classes.actionGridContent} sx={{minHeight: '48px', maxHeight: '48px', paddingBottom: '8px', justifyContent: "space-between"}}>
            <Grid item>
                <IconButton onClick={handleNotificationsClick} sx={{padding: '10px'}}>
                    <Badge badgeContent={badgeContent} color="primary" sx={{"& .MuiBadge-badge": {backgroundColor: "#ff562b"}}} >
                    {
                        notificationIcon
                    }
                    </Badge>
                </IconButton>
            </Grid>
            <Grid item sx={{display: 'flex', alignItems: 'center'}}>
            {
                staticContent
            }
            </Grid>
            <Grid item sx={{justifyContent: "flex-end", display: 'flex'}}>
            {
                actionInfos.actions.map(({label, name, disabled = false}, i) => 
                <Grid item key={i} sx={{paddingLeft: '8px'}}>
                    {    
                        <Button 
                            variant="contained"
                            sx={!disabled? {backgroundColor:'#ff562b',color:'white'}: {}}
                            onClick={() => handleOnClick(name)}
                            disabled={disabled}
                        >
                        {label}
                        </Button>
                    }
                    </Grid>)
            }
            </Grid>
        </Grid>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleNotificationsClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
        >
            <List dense>
            {
                notifications.map((x,i) => 
                    <ListItem 
                        key={i}
                        sx={{minWidth: '200px'}}
                        secondaryAction={
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteNotification(i)}>
                              <DeleteIcon />
                            </IconButton>
                          }>
                        <ListItemText>{x}</ListItemText>
                    </ListItem>)
            }
            </List>
      </Menu>
        </React.Fragment>
    )
}

export default ActionBar;