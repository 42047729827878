import React, { useState, useEffect } from "react";
import { getAllBrands, postBrand, deleteBrand, getAllAAIABrands } from '../../services/ApiService';
import TemplateManage from "../TemplateManage";
import {DateCell} from 'jnpsoft-react-utilities/dist/JnpTable/Commons';
import Grid from '@mui/material/Grid';
import GeneralTextField from "../GeneralTextField";
import GeneralVirtualizedAutocomplete from '../GeneralVirtualizedAutocomplete';


const brandColumnDefs = [
    {
        Header: 'Id',
        accessor: 'id',
        maxWidth: 60,
    },
    {
        Header: 'Brand',
        accessor: 'name'
    },
    {
        Header: 'Brand ID',
        accessor: 'brandAAIAID'
    },
    {
        Header: 'Sub Brand ID',
        accessor: 'subBrandAAIAID'
    },
    {
        Header: 'Date',
        Cell: DateCell,
        accessor: 'date'
    }    
];

const createBrand = (id = -1, name = '') => ({
    id,
    name
});

const ManageBrand = ({name, icon}) => {

    const setBrand = (brand, setBrand) => setBrand({...brand});
    const [aaiaBrands, setAAIABrands] = useState([]);

    useEffect(() => {
        getAllAAIABrands(setAAIABrands);
    },[]);

    const validations = [
        (brand) => {
            const error = brand.name && brand.name.length ? undefined : "Brand name is required";
            return {name: error};
        },
    ];

    const filterAAIABrandId = ({brandAAIAID, subBrandAAIAID}, aaiaBrands) => {
        const aaiaBrandOptions = [...new Set(aaiaBrands.filter(x => !Boolean(subBrandAAIAID) || x.subBrandId === subBrandAAIAID).map(x => x.brandId))].sort().map((val, index) => {return {id: index, value: val}});
        const subAAIABrandOptions = [...new Set(aaiaBrands.filter(x => !Boolean(brandAAIAID) || x.brandId === brandAAIAID).map(x => x.subBrandId))].sort().map((val, index) => {return {id: index, value: val}});
        return {aaiaBrandOptions, subAAIABrandOptions};
    };

    const handleBrand = (propertyName, value, brand, setBrand) => {
        setBrand({...brand, [propertyName]: value});
    };

    return (
        <TemplateManage
            keyPropertyId="id"
            label="Brand"
            title={name}
            titleIcon={icon()}
            editUri="/brand-overview"
            columnDefs={brandColumnDefs}
            createDefaultObject={createBrand}
            getAllObjects={getAllBrands}
            postObject={postBrand}
            deleteObject={deleteBrand}
            validations={validations}
            defaultSortColumn="name"
            renderInputs={({editValue, setEditValue, errors}) => {
                
                const {aaiaBrandOptions, subAAIABrandOptions} = filterAAIABrandId(editValue, aaiaBrands);
                const selectedBrandAAIAID = aaiaBrandOptions.find(x => x.value === editValue.brandAAIAID) || '';
                const selectedSubBrandAAIAID = subAAIABrandOptions.find(x => x.value === editValue.subBrandAAIAID) || '';
                
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <GeneralTextField 
                                keyPropertyName="name"
                                label="Name"
                                itemValue={editValue}
                                setItemValue={v => setBrand(v, setEditValue)}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralVirtualizedAutocomplete
                                options={aaiaBrandOptions}
                                value={selectedBrandAAIAID}
                                label="Brand ID"
                                keyId="value"
                                keyName="value"
                                setId={v => handleBrand("brandAAIAID", v, editValue, setEditValue)}
                                handleError={ (p) => !!errors['brandAAIAID'] }
                                getHelperText={ (p) => errors['brandAAIAID'] || '' }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralVirtualizedAutocomplete
                                options={subAAIABrandOptions}
                                value={selectedSubBrandAAIAID}
                                label="Sub Brand ID"
                                keyId="value"
                                keyName="value"
                                setId={v => handleBrand("subBrandAAIAID", v, editValue, setEditValue)}
                                handleError={ (p) => !!errors[p] }
                                getHelperText={ (p) => errors[p] || '' }
                            />
                        </Grid>
                    </Grid>
                )
            }}
        />
    );
}

export default ManageBrand;