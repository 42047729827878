import React from "react";

import ListItemButton  from '@mui/material/ListItemButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';

import {useHistory, useLocation} from "react-router-dom";

const DrawerLinkButton = ({linkInfo}) => {

  let history = useHistory();
  const location = useLocation();

  const navigateTo = route => history.push(route);

  return (
  <Tooltip title={linkInfo.name}>
    <ListItemButton selected={location.pathname === `/${linkInfo.route}`} onClick={() => navigateTo(`/${linkInfo.route}`)}>
      <ListItemIcon>{linkInfo.icon()}</ListItemIcon>
      <ListItemText>{linkInfo.name}</ListItemText>
    </ListItemButton>
  </Tooltip>
  )
}

export default DrawerLinkButton;