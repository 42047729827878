import React, {useContext, useEffect} from 'react';
import { makeStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx'
import {EditBarContext} from '../context';

export const EditBarWidth = 400;

const useStyles = makeStyles((theme) => ({
	drawer: {
		overflow: 'hidden',
		width: EditBarWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		zIndex: 1500,
	},
	drawerOpen: {
		width: EditBarWidth,
		backgroundColor: '#F5F5F5',
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		width: 0,
		backgroundColor: '#F5F5F5',
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),	
		
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	container: {
		padding: theme.spacing(2),
		overflowY: 'auto',
		paddingBottom: theme.spacing(7),
	}
}));

const EditBar = ({open = true, children}) => {

	const classes = useStyles();
	const {setEditBarOpen} = useContext(EditBarContext);

	useEffect(() => {
		setEditBarOpen(open);
	},[open])

    return (						
        <Drawer 
			anchor='right'
			open={open}
			variant="persistent"
            classes={{
                paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
                }),
            }}
        >							
			<div className={classes.toolbar}></div>
			<div className={classes.container}>
				{children}
			</div>
        </Drawer>	
    );
}

export default EditBar;