import FileSaver from 'file-saver';

export const createEffect = (getAllObjects, setObjects, afterEffect = () => {}) => () => {
    setAllObjects(getAllObjects, setObjects);
    afterEffect();
    return () => {
        setObjects([]);
    }
};

export const setAllObjects = (getAllObjects, setObjects) => {
    getAllObjects((data) => {
        if (data) {
            setObjects(data);
        }
    });
};

export const createDescription = (mapo) => ({row, value}) => (
	<div style={{width: "100%"}}>{mapo[value] || ''}</div>
);

export const createFilter = (mapo) => (rows, fieldName, fragment) => {
	return rows.filter( (r) => (mapo[r.original[fieldName]] || '').includes(fragment) );
}

export const undefinedOrNull = (value) => {
    return value === undefined || value === null;
}

export const fetchIdFromUrl = () => {
    const url = window.location.href.split("/");
    return url[url.length-1];
}

export const createArrayDescription = (mapo) => ({row, value}) => {
	return <div style={{width: "100%"}}>
    {
        value?
        <ul>
            {
                value.map((d, i) => <li key={i}>{mapo[d] || ''}</li>)
            }
        </ul>
        :
        ''
    }
    </div>
};

export const createAccessorArrayDescription = ({
    value: initialValue,
    row: { index },
    column: { id }
    }) => {
	return <div style={{width: "100%"}}>
    {
        initialValue && initialValue.length > 0?
        <ul>
            {
                initialValue.map((value, i) => <li key={i}>{value || ''}</li>)
            }
        </ul>
        :
        ''
    }
    </div>
};

export const createKeyValueObject = (data, keyId, keyName) => {
    if (data && data.length)
    {
        return data.reduce( (a, d) => {
            return {...a, [d[keyId]]: d[keyName]};
        }, {});
    }
    return [];
}

export const downloadFile = (response) => {
    const fileName = extractFileName(response.headers['content-disposition']);
    const fileType = isExcel(fileName) 
                        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        : "application/octet-stream";
    
    const blob = new Blob([response.data], {type: fileType});
    FileSaver.saveAs(blob, fileName);
}

const extractFileName = (text) => {
    if(text && text.indexOf('filename=') !== -1) {
        return text.split('filename=')[1].split(';')[0].replaceAll('"', '');
    }
    return 'unknown';
}

const isExcel = (filename) => filename?.toLowerCase()?.endsWith(".xlsx");